import { notifications } from '@mantine/notifications'
import moment from 'moment'
import { ErrorCodes, ErrorService } from './error'
import html2pdf from 'html2pdf.js'

export const cleanProformaName = (name: string) => {
  return name.replace(/\s/g, '_').replaceAll('.', '')
}

type GeneratePDFParams = {
  elementId: string
  filename: string
}

export const generatePDF2 = async (params: GeneratePDFParams) => {
  const { elementId, filename } = params

  const element = document.getElementById(elementId)

  if (!element) {
    console.error('Element not found')
    notifications.show({
      color: 'red',
      title: 'Error',
      message: 'Elemento no encontrado',
    })
    throw ErrorService.get(ErrorCodes.ERROR_GENERATING_PDF)
  }

  await html2pdf()
    .from(element)
    .set({
      pagebreak: { mode: ['css', 'legacy'] },
      filename: cleanProformaName(`${filename}_${moment().format('YYYY-MM-DD_HH-mm')}`),
      html2canvas: {
        scale: 6,
      },
      jsPdf: {
        unit: 'mm',
        format: 'A4',
        orientation: 'portrait',
      },
    })
    .save()

  return
}
